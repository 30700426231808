<template lang="pug">
  .form_container
    form(@submit.prevent="validateBeforeSubmit").grid
      .field
        label(for="enterprise_1").tag.off_screen
          span Empresa
        .input
          input(type="text" name="enterprise" id="enterprise_1" placeholder="Empresa"
            v-model="enterprise"
            v-validate="'required'"
            :class="{'invalid': errors.has('enterprise') }"
            data-vv-as="Empresa")
          span(v-show="errors.has('enterprise')").help.alert.text--sm {{ errors.first('enterprise') }}
      .field
        label(for="email_1").tag.off_screen
          span Correo electrónico
        .input
          input(type="email" name="email" id="email_1" placeholder="Correo"
            v-model="email"
            v-validate="'required|email'"
            :class="{'invalid': errors.has('email') }"
            data-vv-as="Correo")
          span(v-show="errors.has('email')").help.alert.text--sm {{ errors.first('email') }}
      .field
        label(for="phone_1").tag.off_screen
          span Teléfono
        .input
          input(type="text" name="phone" id="phone_1" placeholder="Teléfono"
            v-model="phone"
            v-validate="'required|numeric'"
            :class="{'invalid': errors.has('phone') }"
            data-vv-as="Teléfono")
          span(v-show="errors.has('phone')").help.alert.text--sm {{ errors.first('phone') }}
      .actions
        button(type="submit" ref="submitButton").btn.btn--base.submit.flex_content-horizontal.jc_center
          span.text--uppercase.text--ls-sm Enviar
          span.feedback-loading.text--uppercase.text--ls-sm Enviando
        .feedback
          .feedback-success.text--sm.text--ls-sm
            span Gracias: Tu información ha sido enviada, te contactaremos lo antes posible.
          .feedback-error.text--sm.text--ls-sm
            span Error: Algo salió mal, inténtalo de nuevo.
</template>

<script>
import aes256 from 'nodejs-aes256'
import crypto from 'crypto'
import axios from 'axios'

let config = {
  host    : 'smtp.webfaction.com',
  port    : 587,
  from    : '"Neodiko" <mail.sender@neodiko.com>',
  to      : 'maniobras@gruashercules.mx',
  subject : 'Formulario de Contacto - Website Grúas Hércules',
  message : '',
  auth    : {
    user : 'mail_sender',
    pass : '4506lv5ms'
  }
}

export default {
  name: 'include_contactForm',
  data() {
    return {
      enterprise: "",
      email: "",
      phone: ""
    }
  },
  methods: {
    cleanForm() {
      this.enterprise = ""
      this.email = ""
      this.phone = ""
      this.$nextTick(() => this.$validator.reset())
      setTimeout(() => { this.$el.classList.remove("success") }, 3000)
    },
    encryptMessage(json) {
      let password        = crypto.randomBytes(Math.ceil(10 / 2)).toString('hex').slice(0, 50)
      let message         = JSON.stringify(json)
      let messageEncrypt  = aes256.encrypt(password, message)
      let passwordEncrypt = new Buffer(password).toString('base64')
      return messageEncrypt.concat('\n' , passwordEncrypt)
    },
    send() {
      this.$el.classList.add("loading")
      let json = config
      json.message = `Empresa: ${this.enterprise}<br/>Correo: ${this.email}<br/>Teléfono: ${this.phone}`
      json = this.encryptMessage(json)
      axios({
        method:'post',
        url:'//api.neodiko.com/mail/sendMail',
        data: { code: json }
      }).then((response) => {
        this.$el.classList.remove("loading")
        this.$el.classList.add("success")
        this.cleanForm()
      }).catch((error) => {
        this.$el.classList.remove("loading")
        this.$el.classList.add("error")
        console.error(error)
      })
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.send()
          return
        }
      }).catch((error) => console.error(error))
    }
  }
}
</script>